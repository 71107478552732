<template>
  <div class="bg-white">
    <div class="mx-auto max-w-screen-xl overflow-hidden px-4 py-12 sm:px-6 lg:px-8">
      <div class="mt-8">
        <p class="text-center text-base leading-6 text-gray-400">
          &copy; {{ new Date().getFullYear() }} Pilot Car Loads. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>
