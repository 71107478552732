<script setup lang="ts">
withDefaults(defineProps<{ loggedIn?: boolean }>(), { loggedIn: false });

const { logOut } = useAuthStore();

async function logOutAndGoHome() {
  await logOut();
  navigateTo('/');
}
</script>

<template>
  <div class="relative bg-white">
    <div class="flex items-center justify-between px-4 py-6 sm:px-6 md:space-x-10">
      <div>
        <nuxt-link
          to="/"
          class="flex"
        >
          <img
            src="~assets/img/logo.svg"
            class="block h-14"
            alt="Pilot Car Loads Logo"
          >
        </nuxt-link>
      </div>
      <button
        class="text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:text-gray-900 focus:outline-none"
        type="button"
        @click="logOutAndGoHome"
      >
        Log out
      </button>
    </div>
  </div>
</template>
